import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const StarRating = ({
    productDetails,
    prev_rating,
    prev_review,
    itemId,
    primary_color,
    secondary_color,
    primary_highlight_color,
    secondary_highlight_color,
}) => {
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState('');
    const [selectedStar, setSelectedStar] = useState(0);
    const [showProductReviews, setShowProductReviews] = useState(false);
    const [showTextReviewOnly, setShowTextReviewOnly] = useState(false);
    const [productRatings, setProductRatings] = useState({});
    const [isReviewDisabled, setIsReviewDisabled] = useState(false);

    useEffect(() => {
        if (productDetails) {
            const initialRatings = {};
            productDetails.forEach((product) => {
                initialRatings[product.id] = prev_rating || 0;
            });
            setProductRatings(initialRatings);
        }
    }, [productDetails, prev_rating]);

    useEffect(() => {
        if (prev_rating) {
            setRating(prev_rating);
            setSelectedStar(prev_rating);
            setIsReviewDisabled(true);
            setShowProductReviews(true);
        }
        if (prev_review) {
            setReviewText(prev_review);
        }
    }, [prev_rating, prev_review]);

    const handleStarClick = (value) => {
        if (isReviewDisabled) return;

        setSelectedStar(value);
        setRating(value);

        const updatedRatings = {};
        productDetails.forEach((product) => {
            updatedRatings[product.id] = value;
        });
        setProductRatings(updatedRatings);
        if (!showProductReviews && !showTextReviewOnly) {
            Swal.fire({
                title: 'Do you want to rate individual service?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                icon: 'question',
            }).then((result) => {
                if (result.isConfirmed) {
                    setShowProductReviews(true);
                    setShowTextReviewOnly(true);
                } else {
                    handleSubmitReview(value, updatedRatings);
                }
            });
        }
    };

    const handleProductRatingChange = (productId, value) => {
        if (isReviewDisabled) return;

        setProductRatings((prevRatings) => {
            const updatedRatings = { ...prevRatings, [productId]: value };
            const totalRating = Object.values(updatedRatings).reduce((acc, curr) => acc + curr, 0);
            const avgRating = Math.round(totalRating / Object.keys(updatedRatings).length);

            setRating(avgRating);
            setSelectedStar(avgRating);

            return updatedRatings;
        });
    };

    const handleReviewChange = (event) => {
        if (isReviewDisabled) return;

        setReviewText(event.target.value);
    };
    const handleSubmitReview = (selectedRating, updatedRatings) => {
        const payload = {
            itemId,
            overallRating: selectedRating || rating,
            review: reviewText,
            productRatings: updatedRatings || productRatings,
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/review`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => response.json())
            .then(() => {
                Swal.fire({
                    icon: 'success',
                    title: 'Review submitted successfully!',
                    text: 'Thank you for your review.',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                setShowTextReviewOnly(false);
                setShowProductReviews(true);
                setIsReviewDisabled(true);
            })
            .catch((error) => {
                console.error('Error submitting review:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error submitting review. Please try again later.',
                    showConfirmButton: true,
                });
            });
    };
    const starRatingStyle = {
        width: '70%',
        marginLeft: '20%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row-reverse',
        borderTopRightRadius: '75px',
        borderBottomRightRadius: '75px',
        marginBottom: '20px',
        border: 'none',
        position: 'relative',
        background: primary_color,
        boxShadow: '0 1px 1px hsla(0deg 0% 0%/0.075), 0 2px 2px hsla(0deg 0% 0%/0.075), 0 4px 4px hsla(0deg 0% 0%/0.075), 0 8px 8px hsla(0deg 0% 0%/0.075), 0 16px 16px hsla(0deg 0% 0%/0.075)',
    };

    const ratingValueStyle = {
        color: secondary_highlight_color,
        position: 'absolute',
        top: '-7px',
        left: '-61px',
        borderRadius: '50%',
        height: '79px',
        width: '79px',
        background: secondary_color,
        textAlign: 'center',
    };

    return (
        <div className="container">
            <div className="row star-rating" style={starRatingStyle}>
                <div className="col-12 d-flex justify-content-around" style={{ marginTop: '-5px' }}>
                    {[1, 2, 3, 4, 5].map((starValue) => (
                        <span
                            key={starValue}
                            className={starValue <= selectedStar ? 'star filled' : 'star'}
                            onClick={() => handleStarClick(starValue)}
                            style={isReviewDisabled ? { pointerEvents: 'none' } : {}}
                        >
                            &#9733;
                        </span>
                    ))}
                </div>
                <fieldset className="rating-container">
                    <div className="rating-value" style={ratingValueStyle}>
                        {selectedStar}
                    </div>
                </fieldset>
            </div>

            {showProductReviews && (
                <div className="row mt-3">
                    {productDetails.map((product) => (
                        <div key={product.id} className="col-12 mt-2">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6>{product.product_name}</h6>
                                <div>
                                    {[1, 2, 3, 4, 5].map((starValue) => (
                                        <span
                                            key={starValue}
                                            className={starValue <= (productRatings[product.id] || 0) ? 'star filled' : 'star'}
                                            onClick={() => handleProductRatingChange(product.id, starValue)}
                                            style={isReviewDisabled ? { pointerEvents: 'none' } : {}}
                                        >
                                            &#9733;
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {showTextReviewOnly && (
                <div className="row mt-3">
                    <div className="col-12">
                        <textarea
                            rows="5"
                            placeholder="Write your suggestion here"
                            className="form-control"
                            value={reviewText}
                            onChange={handleReviewChange}
                            style={isReviewDisabled ? { pointerEvents: 'none' } : {}}
                        />
                    </div>
                </div>
            )}
            {(showTextReviewOnly) && (
                <div className="col-12 mt-3">
                    <button
                        onClick={() => handleSubmitReview(selectedStar)}
                        className="btn btn-primary w-100"
                        style={{ backgroundColor: primary_color, color: primary_highlight_color }}
                        disabled={isReviewDisabled}
                    >
                        Submit
                    </button>
                </div>
            )}

            {isReviewDisabled && reviewText && (
                <div className='row p-2'>
                    <div className="review-section col-12">
                        <p style={{
                            paddingTop: '20px',
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            fontStyle: 'italic',
                            fontFamily: 'serif',
                            color: '#666'
                        }}>
                            “{reviewText}“
                        </p>
                    </div>
                </div>
            )}

        </div>
    );
};

export default StarRating;
