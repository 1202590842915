import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import ContactForm from './contactForm';
import Slider from 'react-slick';
import '../assets/css_pages/product.css';
import AppHeaderDropdown from '../components/header/MainHeader';
import Footer from '../components/header/footer';
import 'font-awesome/css/font-awesome.min.css';
const HomeView = () => {
    console.log(process.env.REACT_APP_BASE_URL);
    useEffect(() => {
        if (window.location.hash) {
            const sectionId = window.location.hash;
            const section = document.querySelector(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);



        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Subscription successful!',
                    showConfirmButton: false,
                    timer: 2000
                });
                setEmail('');
            } else {
                const errorResponse = await response.text();
                let errorMessage;
                try {
                    const jsonResponse = JSON.parse(errorResponse);
                    errorMessage = jsonResponse.message;
                } catch (e) {
                    errorMessage = 'An unknown error occurred';
                }
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: `Failed to subscribe: ${errorMessage}`,
                    showConfirmButton: true
                });
            }
        } catch (error) {
            console.error('Error subscribing:', error);
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'An error occurred. Please try again later.',
                showConfirmButton: true
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (

        <>
            <AppHeaderDropdown />


            <div className="preloader">
                <div className="frame">
                    <div className="center">
                        <div className="dot-1"></div>
                        <div className="dot-2"></div>

                    </div>
                </div>
            </div>


            <section id="banner">
                <div className="backtotop" bis_skin_checked="1">
                    <a href="#banner"><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
                </div>
                <div className="container zindex" id="title-container">
                    <div className="row banner-section-row">
                        <div className="col-lg-7 banner-txt">
                            <h3>Say Hello To A Seamless, <span>Eco-Friendly Solution!</span></h3>
                            <p>Introducing our innovative digital receipts, designed to revolutionize the way businesses and customers interact post-purchase. Elevate your customer experience and streamline your operations with our all-in-one digital receipts.</p>
                            <a className="no-hover" style={{ textDecoration: 'none', color: 'white' }} href="#overview">Explore Now</a>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-img">
                                <img src="images/phone_and_hand_only.png" alt="banner-img" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section id="overview">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>FEA<span>T</span>URE</h3>
                        </div>
                    </div>
                    <div className="row over-pa">
                        <div className="col-lg-9 col-sm-12 m-auto">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-right unique-style3">
                                        <span className="over-i">
                                            <img width="40" height="40" src="https://img.icons8.com/material-sharp/40/FFFFFF/cloud-print.png" alt="cloud-print" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Proof of Purchase</h3>
                                        <p>Easily provide customers with verified proof of purchase, enhancing trust and
                                            simplifying returns. Our digital receipts serve as secure, timestamped records
                                            that streamline the entire shopping experience, ensuring smooth transaction.</p>
                                        <div className="over-btn">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-left unique-style">
                                        <span className="over-i">
                                            <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/FFFFFF/mobile-package-tracking.png" alt="mobile-package-tracking" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Interactive Slip</h3>
                                        <p>Engage customers with our interactive receipt slips! Packed with clickable links
                                            and embedded content, they offer a richer experience, guiding users to product
                                            information, promotions, and more, right at their fingertips instantly.</p>
                                        <div className="over-btn">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-right unique-style2">
                                        <span className="over-i">
                                            <img width="40" height="40" src="https://img.icons8.com/material-outlined/40/FFFFFF/graph.png" alt="graph" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>Digital Marketing</h3>
                                        <p>Transform every receipt into a marketing opportunity! Leverage integrated tools
                                            to promote special offers, loyalty programs, and targeted campaigns that help to
                                            boost customer engagement and sales directly within every receipt.</p>
                                        <div className="over-btn">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="over-item txt-left unique-style4">
                                        <span className="over-i">
                                            <img width="40" height="40" src="https://img.icons8.com/ios-filled/40/FFFFFF/crowdfunding.png" alt="crowdfunding" />
                                        </span>
                                        <div className="break"></div>
                                        <h3>After Sales Support</h3>
                                        <p>Enhance customer satisfaction with our dedicated after-sales support feature.
                                            Provide instant access to warranties, FAQs, and contacts, ensuring that your
                                            customers feel valued and supported long after the purchase is completed.</p>
                                        <div className="over-btn">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <section id="about">
                <div className="container">
                    <div className="row header-text text-center about-header">
                        <div className="col-lg-12">
                            <h3>AB<span>O</span>UT</h3>
                        </div>
                    </div>
                    <div className="row about-pa">
                        <div className="col-lg-10 col-md-12 m-auto about-item">
                            <div className="row">
                                <div className="col-lg-4 col-sm-8 m-sm-auto col-md-5 text-center">
                                    <div className="about-img ai-one">
                                        <img src="../images/About image - reciepts.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt">
                                        <h4 style={{ wordSpacing: '2px', marginBottom: '20px' }}>Go Green with Paper-less Invoicing.</h4>
                                        <p style={{ wordSpacing: '2px', lineHeight: '1.6' }}>We're committed to promoting sustainability through innovative paperless invoicing solutions. Our digital receipts empower businesses to streamline their invoicing processes while significantly reducing their environmental footprint.<br></br> <br></br> By going green with our user-friendly platform, you not only enhance your operational efficiency but also increase customer engagement and feedback with your business. Join us in making a positive impact, one invoice at a time!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 m-auto about-item col-md-12">
                            <div className="row">
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt sm-reduce-pa">
                                        <div className="p-bars">
                                            <h3>Instant Feedback</h3>
                                            <p style={{ wordSpacing: '2px', lineHeight: '2.6' }}>Empower your customers to share their thoughts with our seamless instant feedback option included in every digital receipt. After their purchase, customers can easily rate their experience and provide comments, helping you gather valuable insights in real-time.<br></br> <br></br> By prioritizing customer communication, you enhance satisfaction and loyalty, turning feedback into actionable improvements that foster lasting relationships. With our digital receipt software, every interaction becomes an opportunity for growth and connection!</p>

                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-4 text-center mob-hide sm-hide col-md-5">
                                    <div className="about-img ai-two">
                                        <img src="images/image 3 reciepts.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-10 m-auto about-item col-md-12">
                            <div className="row">
                                <div className="col-lg-4 col-sm-8 m-sm-auto text-center col-md-5">
                                    <div className="about-img ai-three">
                                        <img src="images/image reciepts replace.png" alt="about-img" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-7 col-md-6">
                                    <div className="about-txt">
                                        <h3>Taxation Compliant</h3>
                                        <p style={{ wordSpacing: '1px' }} >Our digital receipt software is fully taxation compliant, ensuring that all transactions meet regulatory standards. Each receipt is meticulously formatted to include essential tax information, making it easy for businesses and customers to maintain accurate records. Simplify your accounting processes and stay audit-ready with our reliable, compliant solution!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section id="product">
                <div className="container">
                    <div className="row header-text text-center scale-header">
                        <div className="col-lg-12">
                            <h3>SC<span>A</span>LE</h3>
                        </div>
                    </div>
                    <div className="row product-pa">
                        <div className="col-lg-7 mob-mar-bottom">
                            <div className="product-item">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7 product-txt">
                                        <h3>Streamlined Invoice Management</h3>
                                        <p>Simplify your invoicing process with our digital solution. Upload, store, and manage all your invoices in one place, reducing administrative overhead and allowing you to focus on growth.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4 mob-img-hide">
                                        <img src="images/image 4 reciepts.png" alt="product-img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-4">
                            <div className="product-item product-txt">
                                <h3>Enhanced Client Engagement</h3>
                                <p className="next-p">Build stronger client relationships by giving them easy access to invoices, ratings, and reviews, fostering trust and repeat business.</p>
                            </div>
                        </div>
                    </div>

                </div >
            </section >

            <section id="contact">
                <div className="container">
                    <div className="row header-text text-center">
                        <div className="col-lg-12">
                            <h3>CON<span>T</span>ACT</h3>
                        </div>
                    </div>
                    <div className="row contact-pa">
                        <div className="col-lg-8 f-text2">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link zom" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                01. How do I start using your digital storage solution?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            To get started, sign up for an account on our website, choose your plan, and follow the prompts to set up your digital storage.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                02. What are your payment options?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                        <div className="card-body">
                                            We accept multiple payment methods including credit cards, PayPal, and direct bank transfers for your convenience.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                03. How can I share my invoices with clients?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                        <div className="card-body">
                                            You can easily share invoices through unique links generated for each document, making access and management seamless for your clients.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link collapsed zom" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                04. Can customers provide feedback on the services?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                        <div className="card-body">
                                            Yes, we encourage customers to leave ratings and reviews to help us improve our services and build trust within our community.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ContactForm />
                    </div>
                </div>
            </section>

            <div className="modal fade show" id="exampleModal2" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit}>
                            <div className="modal-header">
                                <h5 className="modal-title">Subscription</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group contact-input mb-3">
                                    <input
                                        type="email"
                                        className="form-control box-bg"
                                        placeholder="Email Address"
                                        aria-label="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="dropboxx2" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i> Submitting...
                                        </span>
                                    ) : (
                                        'Subscribe'
                                    )}
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>


            <section id="subscribe">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 m-auto">
                            <div className="sub-main">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <h3>Stay Connected With Us</h3>
                                    </div>
                                    <div className="col-lg-3 col-md-5 subscripe-btn">
                                        <a href="#" data-toggle="modal" data-target="#exampleModal2" >Subcribe Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    );
};



export default HomeView;


