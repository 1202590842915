import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';
import $ from 'jquery';

const InsightsView = ({ branches }) => {
    const [data, setData] = useState({});
    const token = sessionStorage.getItem('token');
    const businessId = JSON.parse(sessionStorage.getItem('userData')).businessId;
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    // Predefined color settings for the pie chart
    const pieChartColors = [
        '#9E0142', '#FEE08B', '#D53E4F', '#E6F598', '#F46D43', '#ABDDA4', '#66C2A5', '#32BBBD', '#5E4FA2', '#e7ae19'
    ];

    useEffect(() => {
        const fetchBusinessInsights = async () => {
            try {
                const now = new Date();
                const currentMonth = now.getMonth() + 1;
                const currentYear = now.getFullYear();
                const formattedDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-business-insights`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        businessId,
                        branch_id: selectedBranch || undefined,
                        date: selectedDate || formattedDate,
                    }),
                });
                const result = await response.json();
                if (result.success) {
                    setData(result.data);
                }
            } catch (error) {
                console.error('Error fetching insights:', error);
            }
        };

        fetchBusinessInsights();
    }, [businessId, token, selectedBranch, selectedDate]);
    useEffect(() => {
        // Initialize select2 only once when the component mounts
        const $branchSelect = $('#branchSelect');
        $branchSelect.select2();

        // Update selectedBranch when select2 value changes
        $branchSelect.on('change', function () {
            setSelectedBranch($(this).val());
        });

        // Cleanup select2 when component unmounts
        return () => {
            $branchSelect.select2('destroy');
        };
    }, []);

    const createChartOptions = (title, categories, seriesName, seriesData, chartType, yAxisOptions = {}, xyAxisOptions = {}, seriesColor = '#81a683') => ({
        chart: {
            type: chartType,
            height: '300px',
            scrollablePlotArea: {
                minWidth: 5,
                scrollPositionX: 0,
            },
        },
        title: { text: title },
        xAxis: {
            categories,
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
            tickInterval: 1,
            ...xyAxisOptions,
        },
        yAxis: {
            title: { text: 'Rating' },
            visible: true,
            tickInterval: 1,
            ...yAxisOptions,
        },
        series: [
            {
                name: seriesName,
                data: seriesData,
                color: seriesColor,
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 12000,
                    },
                    chartOptions: {
                        chart: {
                            height: '450px',
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px',
                                },
                            },
                        },
                        yAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px',
                                },
                            },
                        },
                    },
                },
            ],
        },
    });
    const getCurrentMonth = () => {
        const now = new Date();
        const month = now.getMonth() + 1; // getMonth() returns 0-based index, so add 1
        const year = now.getFullYear();
        return `${year}-${month < 10 ? '0' : ''}${month}`;
    };

    return (
        <div >
            <div className='row align-items-end mb-3'>
                <div className='col-md-8'></div>
                <div className='col-md-2'>
                    <div className="form-group">
                        <label>Select Branch</label>
                        <select id='branchSelect' className='form-control' value={selectedBranch} onChange={(e) => setSelectedBranch(e.target.value)}>
                            <option value='0' selected>All</option>
                            {/* {branches.map((branch) => (
                                <option key={branch.id_branch} value={branch.id_branch}>{branch.branch_name}</option>
                            ))} */}
                            {Array.isArray(branches) ? (
                                branches.map((branch) => (
                                    <option key={branch.id_branch} value={branch.id_branch}>
                                        {branch.branch_name}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No branches available</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className='col-md-2'>
                    <div className="form-group">
                        <label>Select Date</label>
                        <input
                            type='month'
                            className='form-control'
                            value={selectedDate || getCurrentMonth()}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                    </div>
                </div>
            </div>


            <div className='row'>
                {/* Best Selling Services/Products Chart */}
                <div className='col-12' style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '20px' }}>
                    {data.bestSellingServices && data.bestSellingServices.length > 0 ? (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={createChartOptions(
                                'Best Selling Services/Products',
                                data.bestSellingServices?.map((item) => item.service_name) || [],
                                'Services / Product',
                                data.bestSellingServices?.map((item) => item.avg_product_rating) || [],
                                'column',
                                { max: 5 }
                            )}
                        />
                    ) : (
                        <div className="no-data-container">
                            <p className="no-data-found text-center">Data Not Found</p>
                        </div>
                    )}
                </div>
            </div>

            <div className='row'>
                {/* Top Selling Services/Products Chart */}
                <div className='col-12 col-md-6' style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '20px' }}>
                    {data.topSellingServices && data.topSellingServices.length > 0 ? (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                ...createChartOptions(
                                    'Top Selling Services/Products',
                                    data.topSellingServices?.map((item) => item.service_name) || [],
                                    'Services / Product',
                                    data.topSellingServices?.map((item) => item.invoice_count) || [],
                                    'bar'
                                ),
                                xAxis: {
                                    categories: data.topSellingServices?.map((item) => item.service_name) || [],
                                    labels: {
                                        style: {
                                            fontSize: '12px',
                                        },
                                    },
                                    title: {
                                        text: null,
                                    },
                                },
                                yAxis: {
                                    title: {
                                        text: '',
                                    },
                                    labels: {
                                        style: {
                                            fontSize: '12px',
                                        },
                                    },
                                },
                            }}
                        />
                    ) : (
                        <div className="no-data-container">
                            <p className="no-data-found text-center">Data Not Found</p>
                        </div>
                    )}
                </div>

                {/* Top Staff Performance Chart */}
                <div className='col-12 col-md-6' style={{ overflowX: 'auto', whiteSpace: 'nowrap', marginBottom: '20px' }}>
                    {data.staffPerformance && data.staffPerformance.length > 0 ? (
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                                chart: {
                                    type: 'pie',
                                    height: '410px',

                                },
                                title: { text: 'Top Staff Performance' },
                                tooltip: {
                                    pointFormat: '<i class="fa fa-user"></i> <b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
                                    useHTML: true,
                                },
                                plotOptions: {
                                    pie: {
                                        allowPointSelect: true,
                                        cursor: 'pointer',
                                        dataLabels: {
                                            enabled: true,
                                            // format: '{point.y}', 
                                            format: '{point.percentage:.1f}%',
                                            distance: -30,
                                            connectorPadding: 15,
                                            backgroundColor: 'none',
                                            useHTML: true,
                                            // style: {
                                            //     textOutline: '2px solid #000',
                                            // }
                                        },
                                        // Value inside the slice
                                        showInLegend: true, // Optional: Enable the legend for pie chart labels
                                    },
                                },
                                series: [
                                    {
                                        name: 'Staff Rating',
                                        colorByPoint: true,
                                        data: data.staffPerformance?.map((item) => ({
                                            name: item.staff_name,
                                            y: item.avg_staff_service_rating,
                                        })) || [],
                                        colors: pieChartColors,
                                    },
                                ],
                            }}
                        />
                    ) : (
                        <div className="no-data-container">
                            <p className="no-data-found text-center">Data Not Found</p>
                        </div>
                    )}


                </div>
            </div>
        </div >
    );
};

export default InsightsView;
