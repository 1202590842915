import React, { useState } from 'react';
import AppHeaderDropdown from '../components/header/MainHeader';
import Footer from '../components/header/footer';

const LoginView = ({ onLogin, onRegister, isSignUp: propIsSignUp, toggleForm, error }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [businessName, setBusinessName] = useState('');

    const handleRegister = (e) => {
        e.preventDefault();
        console.log('register');
        onRegister({ name, email, password, contactNumber, businessName, isSignUp: true });
    };

    const handleLogin = (e) => {
        e.preventDefault();
        onLogin({ email, password });
    };

    const toggleFormHandler = () => {
        toggleForm();
    };

    return (
        <>
            <div className='main-div-login-signup'>
                <AppHeaderDropdown />
                <div className={`container ${propIsSignUp ? 'right-panel-active' : ''}`} id="container">
                    {/* Sign Up Form */}
                    <div className="form-container sign-up-container">
                        <form onSubmit={handleRegister}>
                            {error && <p className="error-message">{error}</p>}
                            <h1 className="login-title">Create Account</h1>
                            <div className="social-container">
                                <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                                <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                            <span className="login-span">or use your email for registration</span>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <input
                                type="number"
                                placeholder="Contact Number"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Business Name"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                            />
                            <button type="submit" className="login-button">Sign Up</button>
                        </form>
                    </div>

                    {/* Sign In Form */}
                    <div className="form-container sign-in-container">
                        <form onSubmit={handleLogin}>
                            {error && <p className="error-message">{error}</p>}
                            <h1 className="login-title">Sign in</h1>
                            <div className="social-container">
                                <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                                <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                            <span className="login-span">or use your account</span>
                            <input
                                type="text"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="login-button">Sign In</button>
                        </form>
                    </div>

                    {/* Overlay Panels */}
                    <div className="overlay-container">
                        <div className="overlay">
                            <div className="overlay-panel overlay-left">
                                <h1 className="login-title">Welcome Back!</h1>
                                <p className="login-text">To keep connected with us please login with your personal info</p>
                                <button className="login-button ghost" onClick={toggleFormHandler}>Sign In</button>
                            </div>
                            <div className="overlay-panel overlay-right">
                                <h1 className="login-title">Hello, Friend!</h1>
                                <p className="login-text">Enter your personal details and start your journey with us</p>
                                <button className="login-button ghost" onClick={toggleFormHandler}>Sign Up</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default LoginView;
