import React, { useState, useEffect, useCallback } from 'react';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import { debounce } from 'lodash';
import $ from 'jquery';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.min.js';

const ReviewsTable = ({ businessId, token, userbranchData }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('0');
    const filters = {
        fromDate,
        toDate,
        branchId: selectedBranch,
    };
    const handleFilterSubmit = (e) => {
        e.preventDefault();

        const filters = {
            fromDate,
            toDate,
            branchId: selectedBranch,
        };
        fetchReviews(1, searchTerm, rowsPerPage, filters);
    };
    useEffect(() => {
        $('#fromDate').datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true,
        }).on('changeDate', (e) => {
            setFromDate(e.target.value);
        });

        $('#toDate').datepicker({
            format: 'yyyy-mm-dd',
            autoclose: true,
        }).on('changeDate', (e) => {
            setToDate(e.target.value);
        });

        // fetchReviews(currentPage, searchTerm, rowsPerPage);
    }, []);
    const handleClearDates = () => {
        setFromDate('');
        setToDate('');
        setSelectedBranch('0');
        $('#fromDate').val('');
        $('#toDate').val('');
        $('#branchSelect').val('0').trigger('change');
        $('#runButton').trigger("click");
        // console.log('hello');
        fetchReviews(1, '', rowsPerPage, {
            fromDate: '',
            toDate: '',
            branchId: '0',
        });
        // setTimeout(() => {
        //     $('#runButton').trigger("click");
        // }, 500);
    };
    const handleBranchChange = (e) => {
        setSelectedBranch(e.target.value);
    };

    useEffect(() => {
        const $branchSelect = $('#branchSelect');
        $branchSelect.select2();
        $branchSelect.on('change', (e) => {
            setSelectedBranch(e.target.value);
        });
        return () => {
            $branchSelect.select2('destroy');
        };
    }, []);
    useEffect(() => {
        if (!businessId || !token) {
            console.error('Missing businessId or token. Cannot fetch reviews.');
            return;
        }
        fetchReviews(1, '', rowsPerPage);
    }, [businessId, token]);

    const fetchReviews = useCallback(async (page = 1, search = '', limit = 10, filters = {}) => {
        if (!businessId || !token) {
            console.error('Invalid businessId or token');
            return;
        }

        setLoading(true);

        try {
            const queryParams = new URLSearchParams({
                page,
                search,
                limit,
                fromDate: filters.fromDate || '',
                toDate: filters.toDate || '',
                branchId: filters.branchId || '0',
            }).toString();

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetch-all-reviews/${businessId}?${queryParams}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error fetching reviews:', response.status, errorText);
                throw new Error('Failed to fetch reviews');
            }

            const data = await response.json();
            setReviews(data.reviews || []);
            setTotalRows(data.totalRows || 0);
            setCurrentPage(page);
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    },
        [businessId, token]
    );

    const handlePageChange = (page) => {
        console.log('handlePageChange');
        fetchReviews(page, searchTerm, rowsPerPage, filters);
    };
    const debouncedFetchReviews = debounce((fetchReviews, search, rowsPerPage, filters) => {
        fetchReviews(1, search, rowsPerPage, filters);
    }, 1000);

    const handleSearchChange = (e) => {
        const search = e.target.value;
        console.log('handleSearchChange');
        setSearchTerm(search);
        debouncedFetchReviews(fetchReviews, search, rowsPerPage, filters);
    };

    const handleRowsPerPageChange = (newLimit) => {
        setRowsPerPage(newLimit);
        console.log('handleRowsPerPageChange');
        fetchReviews(1, searchTerm, newLimit, filters);
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
            sortable: false,
            width: '10%',
        },
        {
            name: 'Branch',
            selector: (row) => < div className="review-text" > {row.branch_name}</div > || 'N/A',
            sortable: true,
        },
        {
            name: 'Invoice No',
            selector: (row) => (
                <a target='blank' rel="noopener noreferrer" style={{ color: '#81a683', fontWeight: 'bold', textDecoration: 'underline' }} href={`/${row.unique_code}`}>
                    {row.invoice_number}
                </a>
            ),
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: (row) => < div className="review-text" > {row.customer_name}</div > || 'N/A',
            sortable: true,
        },
        {
            name: 'Customer Cell',
            selector: (row) => <div className="review-text">{row.customer_cell}</div> || 'N/A',
            sortable: true,
        },
        {
            name: 'Rating',
            selector: (row) => {
                const rating = Math.min(Math.max(row.rating, 0), 5);
                const stars = Array.from({ length: 5 }, (_, index) => index < rating);
                return (
                    <span>
                        {rating !== null &&
                            stars.map((filled, index) => (
                                <span key={index} className={`star ${filled ? 'filled' : ''}`}>
                                    ★
                                </span>
                            ))}
                    </span>
                );
            },
            sortable: true,
        },
        {
            name: 'Review',
            selector: (row) => row.review || 'No Review',
            sortable: false,
            cell: (row) => <div className="review-text">{row.review}</div>,
        },
    ];

    return (
        <div>
            <div className="col-sm-12">
                <div className="card-box p-0">
                    <h4 className="header-title m-t-0 m-b-30">Filters:</h4>
                    <form id='filter-form' onSubmit={handleFilterSubmit}>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="control-label">From:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fromDate"
                                    placeholder="YYYY-MM-DD"
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="control-label">To:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="toDate"
                                    placeholder="YYYY-MM-DD"
                                />
                            </div>
                            <div className="col-lg-3 col-3 col-md-6 col-sm-12">
                                <label className="control-label">Branch:</label>
                                <select
                                    id="branchSelect"
                                    className="form-control"
                                    value={selectedBranch}
                                    onChange={handleBranchChange}
                                >
                                    <option value="0" >All</option>
                                    console.log('working')
                                    {Array.isArray(userbranchData) ? (
                                        userbranchData.map((branch) => (
                                            <option key={branch.id_branch} value={branch.id_branch}>
                                                {branch.branch_name}
                                            </option>
                                        ))
                                    ) : (
                                        <option disabled>No branches available</option>
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="control-label" style={{ visibility: 'hidden' }}>Not Visible</label>
                                <div className="filters-buttons-div" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <button type="submit" className="btn btn-primary" id="runButton">Run</button>
                                    <a href="#" id="clear-btn" onClick={(e) => { e.preventDefault(); handleClearDates(); }}>
                                        Clear
                                    </a>

                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div >

            <div className='col-12' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CSVLink
                        data={reviews.map((review, index) => ({
                            'S.No': index + 1,
                            'invoice No': review.invoice_number,
                            'Customer Name': review.customer_name,
                            'Customer Cell': review.customer_cell,
                            'Rating': review.rating,
                            'Review': review.review,
                        }))}
                        filename={"reviews.csv"}
                        className="btn btn-primary print-hidden"
                        style={{ margin: '2px' }}
                    >
                        CSV
                    </CSVLink>
                    <button className="btn btn-primary print-hidden" onClick={() => window.print()} style={{ margin: '2px' }}>
                        Print
                    </button>
                </div>
                <input
                    type="text"
                    className='form-control print-hidden'
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '200px' }}
                />
            </div>

            <div className='col-12'>

                <DataTable
                    columns={columns}
                    data={reviews}
                    progressPending={loading}
                    striped
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
                />
            </div>
        </div>
    );
};

export default ReviewsTable;
