import React, { useEffect, useState } from 'react';
import '../App.css';
import Barcode from 'react-barcode';
import { Carousel } from 'react-bootstrap';
import StarRating from './starRatingComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import '../star.css';
const HomeView = ({ data, bannerTop, bannerBottom, taxOrganization, error }) => {
    console.log(taxOrganization);
    const handleStarClick = (value, itemId) => {
    };


    const [loading, setLoading] = useState(true);
    const [imsCode, setImsCode] = useState(null);
    const [imsLink, setImsLink] = useState('');
    const [imsOrg, setImsOrg] = useState('');
    useEffect(() => {
        const delayTimer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(delayTimer);
    }, []);
    useEffect(() => {
        if (data && data.length > 0) {

            console.log('data for invoice_details' + { data });
            setLoading(false);
            if (data[0].ims_code !== null && data[0].ims_code !== 0) {
                const rawImsCode = data[0].ims_code;
                const sanitizedImsCode = rawImsCode.replace(/\D/g, '');
                if (sanitizedImsCode && !isNaN(sanitizedImsCode)) {
                    setImsCode(sanitizedImsCode);
                } else {
                    console.warn('Invalid ims_code:', sanitizedImsCode);
                    setImsCode(null);
                }
            } else {
                console.log('ims_code is null or 0, skipping QR code generation');
                setImsCode(null);
            }
            const validImsLink = data[0].tax_org_link || '';
            setImsLink(validImsLink);
            setImsOrg(data[0].tax_org || null);
        }
    }, [data]);

    useEffect(() => {
        const qrData = imsLink || imsCode;
        if (qrData) {
            const script = document.createElement('script');
            script.src = "https://cdn.jsdelivr.net/npm/qrcode@1.4.4/build/qrcode.min.js";
            script.onload = () => {
                if (window.QRCode) {
                    const canvas = document.getElementById('qrcode_1');
                    window.QRCode.toCanvas(canvas, qrData, {
                        width: 300,
                        color: {
                            dark: "#000",
                            light: "#FFFFFF"
                        }
                    }, function (error) {
                        if (error) {
                            console.error('QR Code generation failed:', error);
                        } else {
                            console.log('QR code generated successfully');
                        }
                    });
                }
            };
            document.body.appendChild(script);
        }
    }, [imsCode, imsLink]);



    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-5">
                <div className="spinner-border text-dark" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }
    if (!data || data.length === 0) {
        return (
            <div className="alert-danger text-center">
                Data Not Found
            </div>
        );
    }
    const printData = () => {
        const elementsToHide = document.querySelectorAll('.data-container:not(#sectionToPrint)');
        elementsToHide.forEach(element => {
            element.style.display = 'none';
        });
        const sectionToPrint = document.getElementById('sectionToPrint');
        window.print();
        elementsToHide.forEach(element => {
            element.style.display = 'block';
        });
    };
    let total_order_quantity = 0;
    let total_amount = 0;
    let total_discount = 0;
    let total_tax = 0;
    return (
        <div>
            <style>{`
            body {
                background-color: #eaecf8;
            }
             .btn-primary {
                    background-color: ${data[0].primary_color ? data[0].primary_color : '#81a683'} !important;
                }
            .btn-primary {
                    background-color:${data[0].primary_color ? data[0].primary_color : '#81a683'} !important;
                    border-color:${data[0].primary_color ? data[0].primary_color : '#81a683'} !important;
                }
            .btn-primary:hover {
                background-color: white;
                color: ${data[0].secondary_color ? data[0].secondary_color : '#81a683'} !important;
                border-color: ${data[0].secondary_color ? data[0].secondary_color : '#81a683'} !important;
            }
            .filled {
                color: ${data[0].secondary_color ? data[0].secondary_color : '#495057'} !important;
            }
               .custom-swal-popup {
                    border-radius: 10px;
                    background-color: ${data[0].secondary_color || '#495057'} !important;
                    color: ${data[0].primary_highlight_color || '#ffffff'} !important;
                    font-size: 16px;
                }
                .custom-confirm-btn {
                    background-color: ${data[0].primary_color || '#81a683'} !important;
                    border-color: ${data[0].primary_highlight_color || '#ffffff'} !important;
                    color: white !important;
                }
                .custom-cancel-btn {
                    background-color: ${data[0].secondary_color || '#495057'} !important;
                    border-color: ${data[0].secondary_highlight_color || '#ffffff'} !important;
                    color: white !important;
                }

                .swal2-confirm {
                        background-color:${data[0].primary_color || '#ffffff'} !important;
                    }

                    .swal2-question {
                        border-color:${data[0].secondary_color || '#495057'} !important;
                        color: ${data[0].primary_color || '#ffffff'} !important;
                    }

                    .swal2-confirm:focus {
                        box-shadow: none !important;
                    }
            @media print {
                   body{
                   color:#666 !important;
                   }
                    /* Hide the print button */
                    .print-button {
                        display: none;
                    }

                    /* Adjust font size for print */
                    body {
                        font-size: 14px; /* Adjust font size as needed */
                    }

                    .svg-div {
                        padding: 10px;
                    }
                    
                    .social-links{
                        display:none;
                    }

                    .rating-card{
                        display:none;
                    }

                    .pipe-svg {
                        max-height: 60px; /* Decrease max-height */
                        margin-left: -5px; /* Adjust margin */
                    }

                    .carousel-row {
                        display: none; /* Hide the Carousel for print */
                    }
                    .invoice-div {
                            padding: 5px; /* Adjust padding for the invoice section */
                        }

                   #invoice {
                    font-size: 12px; /* Ensure consistent font size */
                    color: #666; /* Default color */
                    margin-left:20px;
                    ${data[0].primary_color ? `color: ${data[0].primary_color};` : ''} /* Custom color if available */
                }


                    #qrcode_1 {
                        height: 60px !important;
                        width: 60px !important;
                        margin-bottom: -10px !important;
                        /* Example margin adjustment */
                    }
                    
                   
                .logo{
                    max-height:100px;
                    max-width:100px;
                }
                
                }
        `}</style>
            <div className="data-container " id="sectionToPrint" >
                <div className="data-section card p-3 text-center printable">
                    <button onClick={printData} className="print-button">Print</button>
                    <div className='row d-flex align-items-center logo-div' style={{ marginTop: '20px' }}>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3">
                            {data[0].logo ? (
                                <img src={data[0].logo} style={{ maxHeight: '100px' }} alt="Company Logo" className="logo" />
                            ) : (
                                <div className="no-logo-message">
                                    <p>Please update your business logo.</p>
                                </div>
                            )}
                        </div>

                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 svg-div">
                            <svg width="3px" height="60px" xmlns="http://www.w3.org/2000/svg"><rect x="0" y="0" width="1.5" height="50" fill="grey" rx="1.5" ry="1.5"></rect></svg>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex align-items-center invoice-div" style={{ paddingRight: '0px' }}>
                            <h3 id='invoice' style={{ color: '#666', fontSize: '30px', ...(data[0].primary_color && { color: data[0].primary_color }) }}>Sales Invoice</h3>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 d-flex align-items-center address">
                            {(data[0].branch_address || (data[0].business_address !== 0 && data[0].business_address !== null)) && (
                                <h5 style={{ textAlign: 'right', fontSize: '14px', fontWeight: '600', color: '#666' }}>
                                    {(data[0].branch_address || data[0].business_address).split('\n').map((part, index) => (
                                        <React.Fragment key={index}>
                                            {index > 0 && <br />}
                                            {part.trim()}
                                        </React.Fragment>
                                    ))}
                                </h5>
                            )}
                        </div>
                    </div>
                    <div className='row d-flex' style={{ marginTop: '20px', marginBottom: '10px' }}>
                        <div className="col-8 d-flex align-items-center inv-no">
                            {data[0].invoice_number && (
                                <h5 style={{ textAlign: 'left', color: '#666', fontSize: '12px', fontWeight: 'bold' }}>Invoice#: {data[0].invoice_number}</h5>
                            )}
                        </div>
                        <div className="col-4 d-flex align-items-center inv-date">
                            {data[0].invoice_date && (
                                <h5 style={{ textAlign: 'right', color: '#666', fontSize: '12px' }} id="date-h5">{(data[0].invoice_date)}</h5>
                            )}
                        </div>
                    </div>
                    {bannerTop && bannerTop.length > 0 ? (
                        <div style={{ borderRadius: '20px', marginTop: '14px' }}>
                            <div className="row carousel-row">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                    <Carousel interval={data[0].top_slider_speed || 3000} pause={false} controls={false} indicators={false}>
                                        {bannerTop.map((item, index) => (
                                            <Carousel.Item key={index}>
                                                <a href={item.ads_link} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.banner_path}
                                                        alt={`Slide ${index + 1}`}
                                                        style={{ maxHeight: '300px', objectFit: 'cover', borderRadius: '20px' }}
                                                    />
                                                </a>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <div className="row mt-3 social-links">
                        <div className="col-12 d-flex justify-content-around ">
                            {data[0].facebook && data[0].facebook.trim() !== '' && (
                                <a href={data[0].facebook} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <style type="text/css">
                                            {`
                                                .st0 { fill: none; stroke: ${data[0].primary_color || "#000000"}; stroke-width: 3; stroke-miterlimit: 50; }
                                                .st1 { fill: none; stroke: ${data[0].primary_color || "#000000"}; stroke-width: 3; stroke-miterlimit: 50; }
                                                .st2 { fill: none; stroke: ${data[0].primary_color || "#000000"}; stroke-width: 3; stroke-miterlimit: 50; }
                                                `}
                                        </style>
                                        <path className="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02C50,46.65,46.65,50,42.51,50z" />
                                        <path className="st2" d="M24.03,50c0.04-0.41,0.11-0.82,0.11-1.23c0.01-5.67-0.02-11.34,0.03-17.01c0.01-0.97-0.24-1.35-1.26-1.29c-1.38,0.08-2.78-0.02-4.17,0.04c-0.9,0.04-1.3-0.22-1.27-1.19c0.05-1.88,0.04-3.76,0-5.63c-0.02-0.86,0.3-1.22,1.18-1.2c1.46,0.04,2.93-0.03,4.39,0.02c0.84,0.03,1.1-0.27,1.11-1.1c0.02-2.32-0.1-4.68,0.26-6.96c0.69-4.33,3.87-7.2,8.2-7.51c2.23-0.16,4.5,0.1,6.74,0.13c0.65,0.01,0.85,0.33,0.85,0.91c0,1.77-0.02,3.53,0.01,5.3c0.01,0.81-0.38,0.99-1.11,0.99c-1.46,0-2.93,0-4.38,0.17c-1.44,0.16-2.21,1.03-2.28,2.49c-0.08,1.61-0.01,3.23-0.04,4.84c-0.01,0.76,0.49,0.72,0.99,0.72c1.8,0,3.61,0.03,5.41-0.01c0.84-0.02,1.15,0.26,1.03,1.12c-0.27,1.93-0.49,3.87-0.7,5.81c-0.09,0.84-0.48,1.13-1.33,1.09c-1.42-0.07-2.86,0.03-4.28-0.04c-0.92-0.04-1.14,0.3-1.13,1.16c0.03,5.67,0.02,11.34,0.01,17.01c0,0.45-0.03,0.9-0.05,1.35C29.59,50,26.81,50,24.03,50z" />
                                    </svg>
                                </a>
                            )}
                            {data[0].instagram && data[0].instagram.trim() !== '' && (
                                <a href={data[0].instagram} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <style>{`
                                                    .st0 { fill: none; stroke:${data[0].primary_color || "#000000"};- stroke-width: 3; stroke-miterlimit: 10; }
                                                    .st1 { fill: none; stroke:${data[0].primary_color || "#000000"}; stroke-width: 3; stroke-width: 0.759; stroke-miterlimit: 10; }
                                                   `}
                                        </style>
                                        <path className="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02C50,46.65,46.65,50,42.51,50z" />
                                        <circle fill={data[0].primary_color || '#000000'} style={{ strokeWidth: '3' }} className="st1" cx="25" cy="25" r="15.18" />
                                        <circle fill={data[0].primary_color || '#000000'} cx="39.47" cy="9.51" r="1.32" />
                                    </svg>
                                </a>
                            )}
                            {data[0].tiktok && data[0].tiktok.trim() !== '' && (
                                <a href={data[0].tiktok} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', strokeWidth: '3', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02 C50,46.65,46.65,50,42.51,50z"
                                        />
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            style={{ fill: 'none', strokeWidth: '3', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            d="M22.66,19.69c0,2.67,0,4.9,0,7.26c-0.58,0-1.09,0.02-1.59,0c-2.63-0.13-4.81,1.78-4.99,4.37c-0.17,2.38,1.9,4.72,4.34,4.91 c2.45,0.19,4.79-1.89,4.85-4.51c0.09-4.06,0.04-8.12,0.04-12.18c0-4.21,0-8.43,0-12.86c2.36,0,4.59-0.03,6.81,0.05 c0.26,0.01,0.67,0.62,0.73,1c0.7,4.42,3.15,6.98,7.68,7.49c0.34,0.04,0.86,0.64,0.87,1c0.09,1.91,0.04,3.83,0.04,5.95 c-3.07,0.19-5.74-0.79-8.58-2.02c-0.03,0.71-0.08,1.26-0.09,1.82c-0.01,3.47,0.09,6.95-0.05,10.42 c-0.23,5.89-5.19,10.69-11.16,10.95c-6.69,0.3-11.96-3.86-12.88-10.18c-0.87-5.94,2.98-11.61,8.93-12.98 C19.18,19.81,20.85,19.85,22.66,19.69z M21.62,20.69C15.66,19.97,10,24.76,9.58,30.71c-0.44,6.19,4.5,11.66,10.8,11.96 c5.9,0.28,11.26-4.8,11.38-10.88c0.08-3.79,0.01-7.58,0.03-11.37c0-0.56,0.08-1.12,0.16-2c2.74,1.67,5.33,2.93,8.49,2.9 c0-1.44-0.06-2.77,0.02-4.09c0.06-1.02-0.33-1.33-1.33-1.49c-3.93-0.65-6.37-3.11-7.14-7c-0.08-0.41-0.18-0.82-0.27-1.23 c-1.81,0-3.53,0-5.42,0c0,0.75,0,1.38,0,2.01c0,7.37,0.02,14.74-0.01,22.11c-0.01,3-2.27,5.23-5.27,5.32c-3.27,0.1-5.73-1.96-5.97-5 c-0.23-2.92,1.96-5.45,5.02-5.79c0.51-0.06,1.01-0.09,1.54-0.14C21.62,24.16,21.62,22.47,21.62,20.69z"
                                        />
                                    </svg>
                                </a>
                            )}
                            {data[0].twitter && data[0].twitter.trim() !== '' && (
                                <a href={data[0].twitter} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            d="M22.35,28.92c-1.74,1.86-3.4,3.62-5.05,5.39c-2.56,2.74-5.13,5.48-7.67,8.24c-0.43,0.47-0.88,0.69-1.51,0.65  c-0.66-0.05-1.33-0.01-2-0.01C6.09,43.12,6.06,43.06,6.03,43c0.61-0.66,1.22-1.33,1.83-1.99c4.19-4.47,8.37-8.94,12.58-13.39  c0.41-0.44,0.44-0.72,0.07-1.21c-4.71-6.27-9.4-12.56-14.09-18.84C6.28,7.37,6.15,7.18,5.93,6.87c0.36-0.02,0.58-0.05,0.81-0.05  c3.17,0,6.34,0.01,9.52-0.01c0.54,0,0.88,0.17,1.21,0.6c3.06,4.12,6.14,8.22,9.22,12.33c0.14,0.19,0.29,0.36,0.5,0.62  c1-1.06,1.94-2.05,2.88-3.05c3.12-3.34,6.24-6.69,9.38-10.02c0.24-0.26,0.69-0.41,1.06-0.45c0.74-0.07,1.49-0.02,2.43-0.02  c-0.25,0.32-0.39,0.52-0.56,0.7c-4.43,4.74-8.85,9.49-13.29,14.22c-0.44,0.46-0.39,0.74-0.04,1.21c4.91,6.54,9.81,13.09,14.71,19.65  c0.1,0.13,0.17,0.28,0.32,0.52c-0.29,0.04-0.48,0.08-0.68,0.08c-3.2,0-6.39-0.01-9.59,0.01c-0.55,0-0.88-0.18-1.21-0.61  c-3.23-4.35-6.48-8.69-9.73-13.04C22.73,29.36,22.57,29.18,22.35,28.92z M39.53,40.72c-0.24-0.35-0.39-0.59-0.56-0.81  c-2.22-2.96-4.44-5.92-6.66-8.89c-5.3-7.07-10.6-14.13-15.88-21.21c-0.34-0.46-0.69-0.66-1.27-0.64c-1.26,0.04-2.52,0.01-3.78,0.01  c-0.2,0-0.4,0.03-0.7,0.06c0.19,0.28,0.3,0.46,0.43,0.63c7.25,9.68,14.53,19.35,21.72,29.08c1.01,1.37,2.04,2.1,3.75,1.83  c0.69-0.11,1.4-0.02,2.1-0.02C38.92,40.78,39.15,40.75,39.53,40.72z" />
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                    </svg>
                                </a>
                            )}
                            {data[0].snapchat && data[0].snapchat.trim() !== '' && (
                                <a href={data[0].snapchat} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', strokeWidth: '3', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st3" d="M11.33,36.99c-0.9-0.26-1.77-0.46-2.6-0.76c-0.79-0.29-1.43-0.78-1.76-1.61c-0.46-1.17-0.08-2.32,1.03-2.9  c0.44-0.23,0.92-0.4,1.4-0.54c2.26-0.68,3.62-2.23,4.32-4.49c-0.66-0.29-1.33-0.55-1.97-0.86c-1.42-0.7-2.05-2.28-1.53-3.77  c0.45-1.29,1.87-2.07,3.23-1.78c0.18,0.04,0.36,0.08,0.68,0.16c0-0.92-0.02-1.77,0-2.62c0.15-4.64,3.6-8.84,8.06-10.05  c5.83-1.58,12.14,2.11,13.46,8.37c0.24,1.15,0.21,2.37,0.29,3.55c0.02,0.22,0,0.44,0,0.62c0.64,0,1.27-0.1,1.84,0.02  c1.28,0.27,2.05,1.25,2.19,2.57c0.11,1.09-0.6,2.38-1.64,2.9c-0.66,0.33-1.36,0.59-2.05,0.89c0.7,2.42,2.25,3.94,4.65,4.61  c0.27,0.07,0.54,0.16,0.79,0.28c0.85,0.39,1.44,1.01,1.49,1.98c0.05,0.97-0.34,1.81-1.19,2.24c-0.85,0.43-1.79,0.68-2.7,0.99  c-0.19,0.07-0.4,0.09-0.63,0.13c-0.02,0.12-0.04,0.24-0.07,0.36c-0.35,1.66-1.43,2.54-3.11,2.52c-0.69-0.01-1.38-0.07-2.06-0.11  c-0.9-0.05-1.72,0.18-2.49,0.65c-0.83,0.5-1.67,0.97-2.53,1.41c-2.58,1.3-5.1,1.04-7.56-0.36c-0.47-0.27-0.97-0.49-1.4-0.81  c-1.22-0.88-2.57-1-3.99-0.84c-0.53,0.06-1.09,0.1-1.61,0.02C12.38,39.54,11.58,38.63,11.33,36.99z" />
                                    </svg>
                                </a>
                            )}
                            {data[0].whatsapp && data[0].whatsapp.trim() !== '' && (
                                <a href={data[0].whatsapp} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', strokeWidth: '3', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                        <g>
                                            <path
                                                fill={data[0].primary_color || '#000000'}
                                                className="st0"
                                                style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                                class="st4" d="M8.86,41.73c0.09-0.03,0.14-0.04,0.18-0.05c1.95-0.55,3.9-1.1,5.84-1.65c0.18-0.05,0.31-0.03,0.47,0.07   c2.79,1.76,5.82,2.73,9.12,2.83c1.55,0.04,3.07-0.09,4.58-0.44c1.86-0.43,3.61-1.13,5.24-2.13c2.7-1.64,4.84-3.85,6.38-6.61   c1.16-2.07,1.87-4.29,2.14-6.65c0.2-1.75,0.17-3.49-0.14-5.22c-0.49-2.75-1.54-5.26-3.21-7.5c-2.36-3.18-5.43-5.37-9.21-6.54   c-2.32-0.72-4.69-0.93-7.11-0.69c-1.6,0.16-3.15,0.51-4.63,1.1c-4.4,1.75-7.67,4.75-9.72,9.02c-1.68,3.49-2.13,7.17-1.45,10.98   c0.46,2.58,1.46,4.95,3.02,7.06c0.42,0.57,0.45,1.02,0.21,1.65C9.97,38.53,9.44,40.11,8.86,41.73z" />
                                            <path
                                                fill={data[0].primary_color || '#000000'}
                                                className="st0"
                                                style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                                class="st5" d="M30.6,35.9c-1.12,0.02-2.15-0.37-3.18-0.74c-2.55-0.91-4.79-2.31-6.74-4.18c-1.65-1.58-3.05-3.35-4.31-5.26   c-0.83-1.25-1.54-2.56-1.95-4.01c-0.53-1.91-0.43-3.77,0.56-5.51c0.32-0.56,0.76-1.07,1.19-1.56c0.35-0.39,0.82-0.57,1.36-0.55   c0.32,0.01,0.63,0.03,0.95,0.01c0.53-0.03,0.86,0.24,1.04,0.7c0.36,0.88,0.69,1.77,1.04,2.66c0.21,0.54,0.38,1.11,0.65,1.63   c0.43,0.82,0.17,1.5-0.3,2.16c-0.32,0.45-0.68,0.86-1.03,1.29c-0.29,0.35-0.32,0.62-0.1,1.02c0.96,1.74,2.14,3.31,3.65,4.61   c1.16,1,2.47,1.74,3.86,2.36c0.4,0.18,0.65,0.11,0.93-0.24c0.58-0.75,1.15-1.5,1.71-2.26c0.33-0.45,0.57-0.57,1.08-0.33   c1.06,0.5,2.09,1.04,3.14,1.57c0.43,0.22,0.86,0.46,1.29,0.68c0.31,0.16,0.49,0.38,0.48,0.74c-0.04,1.11-0.1,2.23-0.84,3.12   c-1.01,1.2-2.29,1.98-3.92,2.06C30.96,35.88,30.78,35.89,30.6,35.9z" />
                                        </g>
                                    </svg>
                                </a>
                            )}
                            {data[0].youtube && data[0].youtube.trim() !== '' && (
                                <a href={data[0].youtube} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', strokeWidth: '3', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                        <g>
                                            <path
                                                fill={data[0].primary_color || '#000000'}
                                                class="st7" d="M25.37,11.36c2.78,0,5.55,0.06,8.33,0.15c1.53,0.05,3.07,0.07,4.6,0.22c2.26,0.22,3.89,1.37,4.8,3.48   c0.38,0.88,0.54,1.82,0.66,2.76c0.2,1.64,0.3,3.29,0.35,4.94c0.05,1.44,0.02,2.88-0.04,4.32c-0.04,1.16-0.07,2.33-0.16,3.49   c-0.09,1.21-0.24,2.41-0.62,3.57c-0.62,1.91-1.85,3.19-3.81,3.73c-1.02,0.28-2.07,0.29-3.11,0.35c-1.99,0.11-3.98,0.18-5.97,0.21   c-3.49,0.06-6.97,0.06-10.46,0.01c-2.25-0.03-4.5-0.11-6.75-0.22c-1.13-0.06-2.26-0.17-3.33-0.6c-1.51-0.6-2.5-1.71-3.04-3.22   c-0.37-1.02-0.55-2.08-0.65-3.16c-0.24-2.68-0.34-5.37-0.27-8.06c0.05-1.82,0.14-3.63,0.35-5.44c0.16-1.34,0.5-2.65,1.23-3.8   c0.6-0.95,1.47-1.6,2.54-1.97c1.29-0.45,2.64-0.5,3.98-0.54c2.74-0.07,5.47-0.21,8.21-0.23C23.27,11.36,24.32,11.36,25.37,11.36z    M23.65,36.92c3.84,0.04,6.45,0.05,9.06-0.02c2.01-0.05,4.03-0.11,6.02-0.41c0.82-0.12,1.52-0.5,2.07-1.12   c0.75-0.85,1.06-1.89,1.22-2.97c0.31-2.12,0.4-4.26,0.42-6.4c0.01-1.94-0.03-3.89-0.13-5.83c-0.07-1.34-0.21-2.68-0.64-3.97   c-0.54-1.6-1.62-2.54-3.31-2.76c-1.02-0.14-2.05-0.17-3.08-0.23c-2.99-0.15-5.99-0.19-8.99-0.2c-4.35-0.02-8.7,0.08-13.05,0.26   c-0.84,0.04-1.68,0.13-2.49,0.38c-0.76,0.23-1.4,0.65-1.83,1.33c-0.55,0.85-0.8,1.81-0.96,2.79c-0.22,1.38-0.3,2.77-0.36,4.16   c-0.07,1.58-0.06,3.16-0.03,4.74c0.05,2.29,0.08,4.58,0.66,6.82c0.17,0.66,0.46,1.27,0.91,1.79c0.72,0.83,1.69,1.17,2.75,1.3   c1.39,0.18,2.79,0.25,4.19,0.29C19.01,36.95,21.95,36.9,23.65,36.92z" />
                                            <path
                                                fill={data[0].primary_color || '#000000'}
                                                class="st7" d="M20.36,24.54c0-2.16,0-4.31,0-6.47c0-0.14,0-0.28,0.02-0.42c0.03-0.23,0.15-0.3,0.36-0.19   c0.34,0.17,0.68,0.36,1.02,0.54c3.31,1.81,6.61,3.63,9.92,5.44c0.52,0.29,1.05,0.56,1.58,0.84c0.09,0.05,0.17,0.1,0.17,0.2   c0.01,0.11-0.07,0.17-0.16,0.21c-1.54,0.84-3.07,1.68-4.61,2.52c-2.49,1.36-4.98,2.72-7.46,4.08c-0.16,0.09-0.32,0.17-0.48,0.25   c-0.23,0.11-0.32,0.06-0.35-0.19c-0.02-0.17-0.02-0.35-0.02-0.52C20.36,28.74,20.36,26.64,20.36,24.54z M22.04,24.46   C22.03,24.46,22.03,24.46,22.04,24.46c0,1.26,0,2.52,0,3.78c0,0.53,0.1,0.59,0.55,0.34c1.08-0.59,2.16-1.18,3.24-1.77   c1.23-0.67,2.47-1.35,3.7-2.02c0.12-0.07,0.27-0.11,0.27-0.29c0-0.17-0.13-0.23-0.26-0.3c-1.19-0.65-2.39-1.3-3.58-1.95   c-1.17-0.64-2.33-1.27-3.5-1.9c-0.28-0.15-0.43-0.06-0.43,0.27C22.03,21.9,22.04,23.18,22.04,24.46z" />
                                        </g>
                                    </svg>
                                </a>
                            )}
                            {data[0].linkedin && data[0].linkedin.trim() !== '' && (
                                <a href={data[0].linkedin} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', strokeWidth: '5', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            style={{ strokeWidth: '3' }}
                                            className="st1" d="M41.32,50H8.68C3.89,50,0,46.11,0,41.32V8.68C0,3.89,3.89,0,8.68,0h32.64C46.11,0,50,3.89,50,8.68v32.64C50,46.11,46.11,50,41.32,50z" />
                                        <g>
                                            <path style={{ strokeWidth: '3' }} className="st1" d="M39.49,39.52c-1.97,0-3.87,0-5.88,0c-0.03-0.48-0.07-0.9-0.07-1.32c0-2.99,0.02-5.98,0.01-8.97c0-0.63-0.07-1.27-0.17-1.9c-0.21-1.27-0.81-2.26-2.16-2.57c-1.48-0.35-2.99,0.1-3.64,1.4c-0.54,1.1-0.86,2.4-0.92,3.63c-0.13,2.78-0.04,5.58-0.05,8.37c0,0.43,0,0.85,0,1.35c-2.09,0-4.02,0-6.03,0c0-6.47,0-12.87,0-19.36c1.89,0,3.74,0,5.73,0c0.05,0.65,0.1,1.31,0.16,2.13c0.54-0.45,0.99-0.81,1.43-1.19c3.59-3.07,10.85-2.38,11.49,4.47C39.84,30.13,39.49,34.78,39.49,39.52z" />
                                            <path style={{ strokeWidth: '3' }} className="st1" d="M10.95,20.14c1.92,0,3.86,0,5.9,0c0.02,0.42,0.07,0.81,0.07,1.19c0,5.66-0.03,11.31,0.03,16.97c0.01,1.09-0.34,1.36-1.36,1.31c-1.51-0.08-3.02-0.02-4.63-0.02C10.95,33.07,10.95,26.65,10.95,20.14z" />
                                            <path style={{ strokeWidth: '3' }} className="st1" d="M13.82,17.38c-1.95-0.02-3.46-1.55-3.44-3.51c0.01-1.92,1.58-3.48,3.5-3.48c1.98,0,3.53,1.6,3.49,3.59C17.33,15.95,15.81,17.4,13.82,17.38z" />
                                        </g>
                                    </svg>
                                </a>
                            )}
                            {data[0].pintrest && data[0].pintrest.trim() !== '' && (
                                <a href={data[0].pintrest} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', strokeWidth: '3', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st8" d="M22.59,30.66c-0.35,1.34-0.74,2.57-1,3.82c-0.57,2.81-1.83,5.28-3.51,7.57c-0.27,0.37-0.49,0.78-0.86,1.07  c-0.35,0.27-0.6,0.23-0.65-0.24c-0.21-2.1-0.5-4.21-0.12-6.31c0.75-4.15,1.93-8.2,2.89-12.29c0.09-0.38,0.08-0.7-0.06-1.08  c-0.78-2.19-0.74-4.34,0.47-6.38c0.79-1.34,2-2.04,3.59-1.89c1.5,0.14,2.39,1.11,2.51,2.63c0.09,1.09-0.1,2.14-0.42,3.18  c-0.42,1.37-0.82,2.74-1.26,4.1c-0.46,1.42-0.36,2.73,0.77,3.79c1.16,1.09,2.57,1.16,4.02,0.71c1.05-0.32,1.87-0.99,2.5-1.87  c2.34-3.28,3.23-6.98,2.59-10.92c-0.49-3.01-2.46-4.95-5.4-5.76c-3.53-0.97-6.92-0.6-9.96,1.57c-3.15,2.24-4.74,6.47-3.84,9.95  c0.21,0.8,0.57,1.53,1.13,2.14c0.38,0.42,0.52,0.86,0.32,1.42c-0.16,0.45-0.24,0.92-0.37,1.38c-0.22,0.76-0.64,0.95-1.34,0.6  c-2.2-1.08-3.45-2.9-3.93-5.25c-1.07-5.31,0.82-9.52,5.04-12.72c5.19-3.94,13.16-4.25,18.5-0.76c4.09,2.68,5.76,6.53,5.36,11.35  c-0.24,2.97-1.02,5.77-2.83,8.17c-2.31,3.05-5.34,4.71-9.24,4.53C25.52,33.09,23.89,32.3,22.59,30.66z" />
                                    </svg>
                                </a>

                            )}
                            {data[0].telegram && data[0].telegram.trim() !== '' && (
                                <a href={data[0].telegram} target="_blank" rel="noopener noreferrer">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        id="Layer_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 50 50"
                                        style={{ enableBackground: 'new 0 0 50 50', width: '20px', height: '30px' }}
                                        xmlSpace="preserve"
                                    >
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M42.51,50H7.49C3.35,50,0,46.65,0,42.51V7.49C0,3.35,3.35,0,7.49,0h35.02C46.65,0,50,3.35,50,7.49v35.02  C50,46.65,46.65,50,42.51,50z" />
                                        <path
                                            fill={data[0].primary_color || '#000000'}
                                            className="st0"
                                            style={{ fill: 'none', stroke: data[0].primary_color || '#000000', strokeMiterlimit: 10 }}
                                            class="st0" d="M26.13,32.9c-0.58,0.55-1.14,1.07-1.69,1.61c-0.85,0.81-1.7,1.63-2.55,2.43c-0.55,0.52-1.23,0.5-1.67-0.07  c-0.12-0.15-0.21-0.39-0.19-0.58c0.1-1.45,0.23-2.89,0.35-4.34c0.03-0.31,0.08-0.62,0.07-0.93c-0.02-0.37,0.13-0.6,0.4-0.84  c2.64-2.39,5.27-4.79,7.9-7.18c2.29-2.09,4.58-4.18,6.87-6.27c0.22-0.2,0.46-0.39,0.65-0.61c0.09-0.1,0.1-0.28,0.15-0.42  c-0.13-0.03-0.27-0.12-0.38-0.09c-0.31,0.08-0.64,0.16-0.92,0.33c-6.03,3.74-12.06,7.49-18.09,11.24c-0.12,0.08-0.33,0.12-0.46,0.07  c-2.37-0.74-4.73-1.5-7.09-2.26c-0.11-0.04-0.22-0.08-0.33-0.13C8.3,24.43,8.21,23.65,9,23.1c0.41-0.29,0.9-0.48,1.37-0.66  c9.47-3.68,18.93-7.35,28.4-11.02c0.35-0.13,0.71-0.25,1.07-0.32c1.03-0.2,1.74,0.33,1.7,1.38c-0.02,0.78-0.18,1.56-0.33,2.34  c-0.55,2.72-1.13,5.43-1.7,8.15c-0.63,2.99-1.26,5.99-1.89,8.98c-0.37,1.77-0.74,3.55-1.13,5.32c-0.06,0.28-0.16,0.55-0.29,0.81  c-0.4,0.8-1.01,1.03-1.86,0.77c-0.73-0.23-1.27-0.73-1.86-1.17c-2.08-1.55-4.14-3.12-6.22-4.68C26.24,32.97,26.2,32.94,26.13,32.9z" />
                                    </svg>
                                </a>
                            )}

                        </div>
                    </div>
                </div>
                <div className="card details-section printable my-3 p-3 justify-content-center align-items-center rating-card">
                    {(() => {
                        const productDetails = data.map(item => ({
                            product_name: item.product_name,
                            id: item.id,
                            service_rating: item.service_rating > 0 ? item.service_rating : null,
                        }));
                        return (
                            <StarRating
                                onStarClick={handleStarClick}
                                itemId={data[0].invoice_id}
                                prev_review={data[0].review}
                                prev_rating={data[0].rating}
                                productDetails={productDetails}
                                primary_color={data[0].primary_color || '#81a683'}
                                secondary_color={data[0].secondary_color || 'black'}
                                primary_highlight_color={data[0].primary_highlight_color || 'white'}
                                secondary_highlight_color={data[0].secondary_highlight_color || 'white'}
                            />
                        );
                    })()}
                </div>
                <div className="card details-section printable my-3 p-3" id="sectionToPrint">
                    {data[0].cashier && data[0].cashier.trim() !== '' && (
                        <div className="row" style={{ color: '#666' }} >
                            <div className="col-6">
                                <p>Cashier:</p>
                            </div>
                            <div className="col-6">
                                <p className="float-end">{data[0].cashier}</p>
                            </div>
                        </div>
                    )}
                    {data[0].customer_name && data[0].customer_name.trim() !== '' && (
                        <div className="row" style={{ color: '#666' }} >
                            <div className="col-6">
                                <p>Customer Name:</p>
                            </div>
                            <div className="col-6">
                                <p className="float-end">{data[0].customer_name}</p>
                            </div>
                        </div>
                    )}
                    {data[0].customer_cnic && data[0].customer_cnic.trim() !== '' && (
                        <div className="row" style={{ color: '#666' }} >
                            <div className="col-6">
                                <p>Customer Cnic:</p>
                            </div>
                            <div className="col-6">
                                <p className="float-end">{data[0].customer_cnic}</p>
                            </div>
                        </div>
                    )}
                    {data[0].customer_cell && data[0].customer_cell.trim() !== '' && (
                        <div className="row" style={{ color: '#666' }} >
                            <div className="col-6">
                                <p>Customer Cell:</p>
                            </div>
                            <div className="col-6">
                                <p className="float-end">{data[0].customer_cell}</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className="data-section card my-3 p-3 printable" id="sectionToPrint">
                    {data.map((item, index) => {
                        total_order_quantity += parseFloat(item.quantity);
                        total_amount += parseFloat(item.total_price);
                        total_discount += parseFloat(item.discount_amount);
                        total_tax += parseFloat(item.tax_amount);
                        return (
                            <div key={index} style={{ marginTop: '18px' }}>
                                <div className="row" style={{ display: "flex", alignItems: "center" }}>
                                    {item.product_name && item.product_name.trim() !== '' && (
                                        <div className="col-8">
                                            <h3 style={{ fontSize: '18px', fontWeight: 'bold', color: '#666' }}>{item.product_name}</h3>
                                        </div>
                                    )}
                                    <div className="col-4 d-flex justify-content-end" style={{ fontSize: '12px', fontWeight: 'bold', color: '#666' }}>
                                        <p style={{ margin: 0 }}>{parseFloat(item.total_price).toLocaleString('en-IN')}</p>
                                    </div>
                                </div>
                                <div className="row" style={{ color: '#666' }} >
                                    <div className="col-12 pull-left" style={{ fontSize: '12px' }}>
                                        {item.barcode !== 0 && item.barcode !== null && item.barcode !== '' && (
                                            <p style={{ color: '#666' }}>Barcode: {item.barcode} </p>
                                        )}
                                        {item.unit_price && (
                                            <p style={{ color: '#666' }}>Unit Price: {parseFloat(item.unit_price).toLocaleString('en-IN')} </p>
                                        )}
                                        {item.quantity && (
                                            <p style={{ color: '#666' }}>Quantity: {parseFloat(item.quantity).toLocaleString('en-IN')} </p>
                                        )}
                                        {item.discount_percentage !== 0 && item.discount_percentage !== null && (
                                            <p style={{ color: '#666' }}> Discount: {item.discount_percentage}%: {parseFloat(item.discount_amount).toLocaleString('en-IN')} </p>
                                        )}
                                        {item.tax_percentage && (
                                            <p style={{ color: '#666' }}>GST({item.tax_percentage}%): {parseFloat(item.tax_amount).toLocaleString('en-IN')}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );

                    })}
                </div>
                <div className="card data-section my-3 p-3 printable" id="sectionToPrint">
                    <h2 style={{ fontSize: '20px', textAlign: 'center', marginBottom: '20px', color: '#666' }}>Thank you for your purchase</h2>
                    <div className="row" style={{ color: '#666' }} >
                        <div className="col-8">
                            <p>No.of Items:</p>
                        </div>
                        <div className="col-4">
                            <p className="float-end">{parseFloat(total_order_quantity).toLocaleString('en-IN')}</p>
                        </div>
                    </div>
                    {data[0].subtotal_amount !== 0 && data[0].subtotal_amount !== null && (
                        <>
                            <div className="row" style={{ color: '#666' }} >
                                <div className="col-8">
                                    <p>Sub total:</p>
                                </div>
                                <div className="col-4">
                                    <p className="float-end">{parseFloat(data[0].subtotal_amount).toLocaleString('en-IN')}</p>
                                </div>
                            </div>
                        </>
                    )}

                    {data[0].discount !== 0 && data[0].discount !== null && (
                        <>
                            <div className="row" style={{ color: '#666' }} >
                                <div className="col-8">
                                    <p>Discount:</p>
                                </div>
                                <div className="col-4">
                                    <p className="float-end">{parseFloat(data[0].discount).toLocaleString('en-IN')}</p>
                                </div>
                            </div>
                        </>
                    )}
                    {data[0].total_tax !== 0 && data[0].total_tax !== null && (
                        <>
                            <div className="row" style={{ color: '#666' }} >
                                <div className="col-8">
                                    <p>Payable Before Tax:</p>
                                </div>
                                <div className="col-4">
                                    <p className="float-end">{parseFloat(data[0].total_before_tax).toLocaleString('en-IN')}</p>
                                </div>
                            </div>
                            <div className="row" style={{ color: '#666' }} >
                                <div className="col-8">
                                    <p>Tax:</p>
                                </div>
                                <div className="col-4">
                                    <p className="float-end">{parseFloat(data[0].total_tax).toLocaleString('en-IN')}</p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {data[0].total_after_tax !== 0 && data[0].total_after_tax !== null && (
                    <div
                        className="card data-section my-3 p-3 printable payable-div"
                        id="sectionToPrint"
                        style={{ backgroundColor: data[0].primary_color || '#81a683' }}
                    >
                        <div
                            className="row d-flex align-items-center payable-card"
                            style={{
                                backgroundColor: data[0].primary_color || '#81a683',
                                color: data[0].primary_highlight_color || 'white',
                            }}
                        >
                            <span className="col-6" style={{ fontSize: '24px' }}>
                                Total Payable:
                            </span>
                            <span className="col-6 text-right" style={{ fontSize: '24px' }}>
                                {parseFloat(data[0].total_after_tax).toLocaleString('en-IN')}
                            </span>

                            {(data[0].points_used > 0 || data[0].points_earned > 0 || data[0].total_points > 0) && (
                                <>
                                    <h5 style={{ textAlign: 'center' }}>Loyalty Points</h5>

                                    {data[0].points_used > 0 && (
                                        <>
                                            <span className="col-6" style={{ fontSize: '14px' }}>
                                                Points Used:
                                            </span>
                                            <span className="col-6 text-right" style={{ fontSize: '14px' }}>
                                                {data[0].points_used.toFixed(0)}
                                            </span>
                                        </>
                                    )}

                                    {data[0].points_earned > 0 && (
                                        <>
                                            <span className="col-6" style={{ fontSize: '14px' }}>
                                                Points Earned:
                                            </span>
                                            <span className="col-6 text-right" style={{ fontSize: '14px' }}>
                                                {data[0].points_earned.toFixed(0)}
                                            </span>
                                        </>
                                    )}

                                    {data[0].total_points > 0 && (
                                        <>
                                            <span className="col-6" style={{ fontSize: '14px' }}>
                                                Total Points:
                                            </span>
                                            <span className="col-6 text-right" style={{ fontSize: '14px' }}>
                                                {data[0].total_points.toFixed(0)}
                                            </span>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}

                <div className="card data-section my-3 p-3 printable" id="sectionToPrint">
                    {data[0].payment_mode && data[0].payment_mode.trim() !== '' && (
                        <div className="row d-flex align-items-center" style={{ color: '#666' }} >
                            <div className="col-6">
                                <p>Payment Mode:</p>
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                                <p className="">{data[0].payment_mode}</p>
                            </div>
                        </div>
                    )}
                    {data[0].paid_amount !== 0 && data[0].paid_amount !== null && (
                        <div className="row d-flex align-items-center" style={{ color: '#666' }} >
                            <div className="col-6 d-flex justify-content-start align-items-center">
                                <p> Paid:</p>
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                                <p>{parseFloat(data[0].paid_amount).toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                    )}
                    {data[0].remaining_amount !== 0 && data[0].remaining_amount !== null && (
                        <div className="row d-flex align-items-center" style={{ color: '#666' }} >
                            <div className="col-6 d-flex justify-content-start align-items-center">
                                <p> Remaining Amount:</p>
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center">
                                <p>{parseFloat(data[0].remaining_amount).toLocaleString('en-IN')}</p>
                            </div>
                        </div>
                    )}
                </div>


                {imsCode !== null && imsCode.toString() !== '0' && (
                    <div className="card data-section my-3 p-3 printable" id="sectionToPrint">
                        <div className="row" style={{ color: '#666' }}>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="text-center">
                                    <div>
                                        <canvas id="qrcode_1" style={{ height: '150px', width: '150px' }}></canvas>
                                    </div>
                                    <p className="" style={{ fontSize: '', fontWeight: 'bold', marginTop: '10px', color: '#000' }}>
                                        {imsCode}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="text-center">
                                    {taxOrganization && taxOrganization.length > 0 ? (
                                        taxOrganization.map((item, index) => (
                                            <div key={index} className="text-center">
                                                {item.tax_org_logo_path && item.tax_org_logo_path.trim() !== '' ? (
                                                    <>
                                                        <img
                                                            src={item.tax_org_logo_path}
                                                            style={{ maxHeight: '100px' }}
                                                            alt={`Tax Organization Logo ${index + 1}`}
                                                            className="logo"
                                                        />
                                                        <p style={{ fontSize: '12px' }}>Verify this invoice by scanning the QR code.</p>
                                                    </>
                                                ) : (
                                                    <p style={{ fontSize: '12px' }}>Tax organization logo not available.</p>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ fontSize: '12px' }}></p>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {data[0].invoice_number !== 0 && data[0].invoice_number !== null && (
                    <div className="card data-section my-3 p-3 printable" id="sectionToPrint">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            </div>
                        </div>

                        <div className="">
                            <div className="row" style={{ color: '#666' }} >
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center" id="barcode" >
                                    <Barcode
                                        value={data[0].invoice_number}
                                        height="50px" width="1.8px"
                                        textOptions={{ textAlign: 'center', textBaseline: 'top', fillStyle: '#666 !important', color: '#666 !important' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="card data-section my-3 p-3 printable" id="sectionToPrint">
                    {bannerBottom && bannerBottom.length > 0 ? (
                        <div>
                            <div className="row carousel-row ">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                                    <Carousel interval={data[0].bottom_slider_speed || 3000} pause={false} controls={false} indicators={false}>
                                        {bannerBottom.map((item, index) => (
                                            <Carousel.Item key={index}>
                                                <a href={item.ads_link} target="_blank" rel="noopener noreferrer">
                                                    <img
                                                        className="d-block w-100"
                                                        src={item.banner_path}
                                                        alt={`Slide ${index + 1}`}
                                                        style={{ maxHeight: '300px', objectFit: 'cover', borderRadius: '20px' }}
                                                    />
                                                </a>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {data[0].policy && (
                        <div className="row my-3">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <h5 className="text-left" style={{ color: "#666", fontWeight: 600 }}>Policy</h5>
                                <p className="text-justify" style={{ marginTop: "12px" }}>{data[0].policy}</p>
                            </div>
                        </div>
                    )}

                    <hr className="my-3" />
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                            <span>
                                Powered By <a href="https://receipt.mexyon.com/" style={{ color: '#81a683', textDecoration: 'none', ...(data[0].primary_color && { color: data[0].primary_color }) }}>Mexyon</a>
                                <span style={{ marginLeft: '5px', color: '#666', fontSize: '12px' }}>
                                    <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};
export default HomeView;